<template>
  <b-form-group
    label="Skor"
    label-for="score"
  >
    <cleave
      id="score"
      v-model="itemData.score"
      class="form-control"
      :raw="true"
      :options="options.float"
      placeholder="Skor"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Score',
  components: {
    BFormGroup,
    Cleave,
  },
  data() {
    return {
      options: {
        float: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '',
        },
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['csi/getCsiRow']
    },
  },
}
</script>
