<template>
  <b-form-group
    label="Marka"
    label-for="id_com_brand"
  >
    <v-select
      id="id_com_brand"
      v-model="itemData.id_com_brand"
      :options="brands"
      label="title"
      :reduce="brand => brand.id"
      placeholder="Marka"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Brand',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    itemData() {
      return this.$store.getters['csi/getCsiRow']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
  },
  created() {
    this.getBrands()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: [
          'com_brand.id AS id',
          'com_brand.name AS title',
        ],
        where: {
          'com_brand.servicestatus': 1,
        },
      })
    },
  },
}
</script>
