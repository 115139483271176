<template>
  <div>
    <b-row>
      <b-col>
        <brand />
      </b-col>
      <b-col>
        <location />
      </b-col>
      <b-col>
        <department />
      </b-col>
    </b-row>
    <b-row v-if="isUser">
      <b-col>
        <user-person />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <month />
      </b-col>
      <b-col>
        <year />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <goal />
      </b-col>
      <b-col>
        <score />
      </b-col>
      <b-col v-if="!isUser">
        <ranking />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import Brand from '@/views/External/Csi/elements/Brand.vue'
import Department from '@/views/External/Csi/elements/Department.vue'
import Goal from '@/views/External/Csi/elements/Goal.vue'
import Location from '@/views/External/Csi/elements/Location.vue'
import Month from '@/views/External/Csi/elements/Month.vue'
import Ranking from '@/views/External/Csi/elements/Ranking.vue'
import Score from '@/views/External/Csi/elements/Score.vue'
import UserPerson from '@/views/External/Csi/elements/UserPerson.vue'
import Year from '@/views/External/Csi/elements/Year.vue'

export default {
  name: 'CsiForm',
  components: {
    BRow,
    BCol,
    Brand,
    Department,
    Goal,
    Location,
    Month,
    Ranking,
    Score,
    UserPerson,
    Year,
  },
  props: {
    isUser: {
      type: Boolean,
      default: false,
    },
    submitForm: {
      type: Function,
      required: true,
    },
  },
}
</script>
