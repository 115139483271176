<template>
  <b-form-group
    label="Lokasyon"
    label-for="id_com_location"
  >
    <v-select
      id="id_com_location"
      v-model="itemData.id_com_location"
      :options="locations"
      label="title"
      :reduce="item => item.id"
      placeholder="Lokasyon"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Brand',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    itemData() {
      return this.$store.getters['csi/getCsiRow']
    },
    locations() {
      return this.$store.getters['locations/getLocations']
    },
  },
  created() {
    this.getLocation()
  },
  methods: {
    getLocation() {
      this.$store.dispatch('locations/locationsList', {
        select: [
          'com_location.id AS id',
          'com_location.title AS title',
        ],
      })
    },
  },
}
</script>
