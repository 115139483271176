<template>
  <b-form-group
    label="Yıl"
    label-for="year"
  >
    <v-select
      id="year"
      v-model="itemData.year"
      :options="years"
      placeholder="Yıl"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Brand',
  components: {
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      years: [
        Number(this.moment().format('YYYY')) + 1,
        this.moment().format('YYYY'),
        this.moment().format('YYYY') - 1,
      ],
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['csi/getCsiRow']
    },
  },
}
</script>
