<template>
  <b-form-group
    label="Ay"
    label-for="month"
  >
    <v-select
      id="month"
      v-model="itemData.month"
      :options="months"
      label="title"
      :reduce="item => item.id"
      placeholder="Ay"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Brand',
  components: {
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      months: [
        { id: '1', title: 'Ocak' },
        { id: '2', title: 'Şubat' },
        { id: '3', title: 'Mart' },
        { id: '4', title: 'Nisan' },
        { id: '5', title: 'Mayıs' },
        { id: '6', title: 'Haziran' },
        { id: '7', title: 'Temmuz' },
        { id: '8', title: 'Ağustos' },
        { id: '9', title: 'Eylül' },
        { id: '10', title: 'Ekim' },
        { id: '11', title: 'Kasım' },
        { id: '12', title: 'Aralık' },
      ],
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['csi/getCsiRow']
    },
  },
}
</script>
