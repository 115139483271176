<template>
  <b-form-group
    label="Departman"
    label-for="id_com_department"
  >
    <v-select
      id="id_com_department"
      v-model="itemData.id_com_department"
      :options="departments"
      label="title"
      :reduce="item => item.id"
      placeholder="Departman"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Brand',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    itemData() {
      return this.$store.getters['csi/getCsiRow']
    },
    departments() {
      return this.$store.getters['departments/getDepartments']
    },
  },
  created() {
    this.getDepartments()
  },
  methods: {
    getDepartments() {
      this.$store.dispatch('departments/departmentsList', {
        select: [
          'com_department.id AS id',
          'com_department.title AS title',
        ],
        where: {
          'com_department.type': 1,
        },
      })
    },
  },
}
</script>
